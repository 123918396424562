﻿var jquery = require('jquery');
global.jQuery = global.$ = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
var bootstrap = require('bootstrap');
var topNavigation = require('./components/topNavigation');
//var siteSearch = require('./components/siteSearch');
//var popups = require('./components/popups');
//var loginPopup = require('./components/loginPopup');

$(function () {
    topNavigation.initialize();
    //siteSearch.initialize();
    //popups.initialize();
    //loginPopup.initialize();
})